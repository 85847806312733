<template>
  <win-block>
    <template #win-title-left>Топ игроков</template>
    <v-card
        class="mx-auto"
        width="100%"
        flat
        :loading="sparkline_loading"
    >

      <v-sheet color="transparent">
        <v-sparkline
            :value="value"
            :labels="label"
            :gradient="['#F57F17', '#FDD835', '#FFEE58']"
            color="tertiary"
            :fill=true
            smooth
            label-size="3"
            type="bar"
        >

          <template v-slot:label="item">
            {{ item.value }}
          </template>
        </v-sparkline>
      </v-sheet>
    </v-card>
  </win-block>
</template>

<script>

export default {
  components: {
    "win-block": () => import('@/site/componets/winblock'),
  },

  data: () => ({
    sparkline_loading: true,
    label: [],
    value: [],
  }),
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.$axios.get(this.$url.statistics_project_sparkline, {params: {name: 'playersTop'}})
          .then(response => {
            this.label = []
            this.value = []
            response.data.forEach(entry => {
              this.label.push(entry["name"])
              this.value.push(entry["uptime"])
            });
            this.sparkline_loading = false
          })
    },
  }
}
</script>
