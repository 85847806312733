<template>
  <win-block>
    <template #win-title-left>Длительность</template>
    <template #win-title-help>
      <help text="Суммарное время всех игроков проведенное<br/>на сервере в течении дня" />
    </template>
    <v-card
        class="mx-auto"
        width="100%"
        flat
        :loading="sparkline_loading"
    >

      <v-sheet color="transparent">
        <v-sparkline
            :value="value"
            :labels="label"
            :gradient="['#F57F17', '#FDD835', '#FFEE58']"
            color="tertiary"
            :fill=true
            smooth
            label-size="3"
        >

          <template v-slot:label="item">
            {{ item.value }}
          </template>
        </v-sparkline>
      </v-sheet>
    </v-card>
  </win-block>
</template>

<script>

export default {
  components: {
    "win-block": () => import('@/site/componets/winblock'),
    "help": () => import('@/site/componets/help'),
  },
  props: {items: {type: Array}},
  data: function () {
    return {
      sparkline_loading: true,
      label: [],
      value: [],
      // data_server: this.items,
    }
  },
  watch: {
    items: {
      // immediate: true, // немедленный запуск обработки
      // deep: true, // глубокое наблюдение за объектом
      handler(newValue, oldValue) {
        oldValue
        this.label = []
        this.value = []
        // window.console.log("======== watch Длительность ===========", newValue)
        newValue.forEach(entry => {
          let date = this.$lib.formatDate(new Date(entry["created"]), "day");
          this.label.push(date)
          this.value.push(entry["durationSum"])
        })
        this.sparkline_loading = false
      }
    }
  },
}
</script>

<style scoped>

</style>

<i18n>
{
"ru":{
"Must be valid e-mail":"Введите правильную почту",
"E-mail is required":"Почта обязательна"
}
}
</i18n>