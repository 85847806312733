<template>
  <v-row no-gutters justify="center">
    <v-col cols="10">
      <players/>
    </v-col>
    <v-col cols="10">
      <duration :items="server_data"/>
    </v-col>
    <v-col cols="10">
      <online :items="server_data"/>
    </v-col>
  </v-row>
</template>

<script>
import online from "@/site/modules/statistics/project-sparkline-online";
import duration from "@/site/modules/statistics/project-sparkline-duration";
import players from "@/site/modules/statistics/project-sparkline-players";

export default {
  components: {online, duration, players},
  data: () => ({
    server_data: null,
  }),
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.$axios.get(this.$url.statistics_project_sparkline, {params: {name: 'multigraph'}})
          .then(response => {
            // один запрос возвращает данные на оба графика
            this.server_data = response.data
            // window.console.log("=========this.server_data ==========", this.server_data)
          })
    },
  }
}
</script>

<style scoped>

</style>